import concat from 'lodash-es/concat';
import findIndex from 'lodash-es/findIndex';
import map from 'lodash-es/map';
import { FILE_IS_TOO_BIG, WRONG_IMAGE_FORMAT } from 'mk/autogenerated/translations/withUploader.36cd6fe102a2dd7a74b613435c82fbcd'
import picUp from 'mk/common/picUp';
import { photo_upload_url } from 'mk/urls/functions';
import { OldPhotoUploadResponse, PhotoAspect, PhotoMimetype } from 'mk2/schemas';
import React from 'react';

const MAX_UPLOAD_FILE_SIZE = 15 * 1024 * 1024;

export const STATUS = {
    NEW: 1,
    SENDING: 2,
    DONE: 3,
    FAILED: 4,
};

interface OwnUploaderProps {
    type: string;
    previewSize: string;
    multiple?: boolean;
}

export interface UploaderProps extends OwnUploaderProps {
    openFileExplorer();
    cancel(id: number);
    rotate(id: number);
}

export interface Photo {
    id: number;
    status: number;
    errorMsg: string;
    progress: number;
    obj: {[key: string]: any};
    orientation: number;
    thumb: {
        width: number;
        height: number;
        url: string;
    };

    code: string;
    width: number;
    height: number;
    aspect: PhotoAspect;
    mimetype: PhotoMimetype;
}

interface OwnProps {
    photos: Photo[];
    uploader: OwnUploaderProps;
    onChange([]);
}

export const withUploader = (ComposedComponent: React.ComponentClass<any>): any => {
    class ComponentWithUploader extends React.Component<OwnProps> {
        private fileInputElem: HTMLInputElement;
        private lastId = 0;
        private files = [];

        public openFileExplorer() {
            this.fileInputElem.click();
        }

        public startUploadingFiles = () => {
            let fs;

            if (this.props.uploader.multiple) {
                fs = map(this.fileInputElem.files, (f) => this.getNewState(f));
                this.files = this.files.concat(fs);
            } else {
                fs = [this.fileInputElem.files[0]].map((f) => this.getNewState(f));
                this.files = fs;
            }
            this.props.onChange(this.files);
            this.loop();
            this.fileInputElem.value = ''; // so that we can select the same file again later
        };

        public cancel(id) {
            this.files = this.files.filter((f) => f.id !== id);
            this.props.onChange(this.files);
        }

        public rotate(id) {
            const index = findIndex(this.files, {id});
            if (index >= 0) { // photo could be canceled meanwhile, so there's nothing to rotate
                this.files = [
                    ...this.files.slice(0, index),
                    {
                        ...this.files[index],
                        orientation: (this.files[index].orientation + 1) % 4,
                    },
                    ...this.files.slice(index + 1),
                ];
                this.props.onChange(this.files);
            }
        }

        public setFileState(fileId, data) {
            const index = findIndex(this.files, {id: fileId});
            if (index >= 0) { // photo could be canceled meanwhile, so there's nothing to update
                this.files = [
                    ...this.files.slice(0, index),
                    {
                        ...this.files[index],
                        ...data,
                    },
                    ...this.files.slice(index + 1),
                ];
                this.props.onChange(this.files);
            }
        }

        // activates the queue
        public loop() {
            // console.log('loop...');

            // first we check if anything is uploading currently
            if (this.files.some((f) => f.status === STATUS.SENDING)) {
                // console.log('upload already running');
                return;
            }

            // we pick the first available file and upload it
            const newFiles = this.files.filter((f) => f.status === STATUS.NEW);
            if (newFiles.length === 0) {
                // console.log('no available files');
                return;
            }
            const nextFile = newFiles[0];
            this.setFileState(nextFile.id, {status: STATUS.SENDING, progress: 0});
            picUp(nextFile.obj, photo_upload_url(this.props.uploader.type), true, // canPreprocess - we always allow it
                (data) => {
                    // console.log('loop: done successfully');
                    this.setFileState(nextFile.id, {
                        status: STATUS.DONE,
                        thumb: data.photo_versions[this.props.uploader.previewSize],
                        ...data.photo,
                    });
                    this.loop();
                },
                function(errorMsg) { // tslint:disable-line
                    // console.log('loop: failed:' + errorMsg);
                    this.setFileState(nextFile.id, {status: STATUS.FAILED, errorMsg});
                    this.loop();
                }.bind(this),
                function(progress) { // tslint:disable-line
                    // console.log('loop: progress:' + progress);
                    this.setFileState(nextFile.id, {progress});
                }.bind(this));
        }

        public render() {
            // console.log(this.props);
            if (!this.props.uploader) {
                return (
                    <ComposedComponent
                        {...this.props}
                    />
                );
            }

            /* crazy invisible input
            we must support android-webkit-4, but it does this: if you call click() method
            on a display-none file-input, it ignores it. so we have to hide it more creatively
            and this approach seems to work */
            return (
                <div>
                    <input
                        id="invisible"
                        ref={this.handleFileInputRef}
                        type="file"
                        accept="image/jpeg,image/png,image/gif"
                        multiple={this.props.uploader.multiple}
                        onChange={this.startUploadingFiles}
                        style={{position: 'absolute', left: -5000, zIndex: -100, width: 0, height: 0}}
                    />
                    <ComposedComponent
                        {...this.props}
                        uploader={{
                            ...this.props.uploader,
                            openFileExplorer: () => this.openFileExplorer(),
                            cancel: (id) => this.cancel(id),
                            rotate: (id) => this.rotate(id),
                        }}
                    />
                </div>
            );
        }

        private getNextId() {
            this.lastId = this.lastId + 1;
            return this.lastId;
        }

        private getNewState(f): Photo {
            let errorMsg = null;
            if (f.size > MAX_UPLOAD_FILE_SIZE) {
                errorMsg = FILE_IS_TOO_BIG;
            } else if (f.type !== '' && f.type !== 'image/jpeg' && f.type !== 'image/png' && f.type !== 'image/gif') { // if the type cannot be determined, the value is empty-string
                errorMsg = WRONG_IMAGE_FORMAT;
            }
            const status = errorMsg ? STATUS.FAILED : STATUS.NEW;
            return {
                id: this.getNextId(),
                status,
                errorMsg,
                progress: 0,
                obj: f,
                orientation: 0,
                thumb: null,
                code: null,
                width: 0,
                height: 0,
                mimetype: null,
                aspect: null,
            };
        }

        private getDoneState(data: OldPhotoUploadResponse): Photo {
            return {
                status: STATUS.DONE,
                errorMsg: null,
                progress: 1,
                obj: null,
                orientation: 0,
                thumb: data.photo_versions[this.props.uploader.previewSize],
                ...data.photo,
                id: this.getNextId(),
            };
        }

        private handleFileInputRef = (el: HTMLInputElement) => {
            this.fileInputElem = el;
        };
    }

    return ComponentWithUploader;
};
