import { csrfToken } from 'mk/common/csrfToken';
import { resizeImage } from 'mk2/containers/PhotosUpload/resize';
import { getLogger } from 'mk2/logger';

const logger = getLogger('common/picUp');

function reportErrorToRaven(msg, event) {
    logger.warn('picUp: ' + msg, {...event});
}

function sendBlob(blob, uploadURL, onDone, onError, onProgress) {
    const fd = new FormData();
    fd.append('file', blob);
    fd.append('csrfmiddlewaretoken', csrfToken());

    const xhr = new XMLHttpRequest();

    xhr.upload.addEventListener('progress', (e) => {
        if (e.lengthComputable) {
            onProgress(e.loaded / e.total);
        }
    }, false);

    xhr.addEventListener('load', function() {
        // console.log('picUp: load');
        if (this.status === 200) {
            const data = JSON.parse(this.response);
            onDone(data);
        } else {
            const msg = this.responseText || ('error ' + this.status);
            onError(msg);
        }
    }, false);

    xhr.addEventListener('error', (e) => {
        const msg = 'error: unknown';
        onError(msg);
        reportErrorToRaven(msg, e);
        // console.error(e);
    }, false);

    xhr.addEventListener('abort', () => {
        const msg = 'error: abort';
        onError(msg);
    }, false);

    xhr.upload.addEventListener('error', (e) => {
        const msg = 'error: upload: unknown';
        onError(msg);
        reportErrorToRaven(msg, e);
        // console.error(e);
    }, false);

    xhr.upload.addEventListener('abort', () => {
        const msg = 'error: upload: abort';
        onError(msg);
    }, false);

    xhr.open('POST', uploadURL);
    xhr.send(fd);
}

export default async function(fileObj, uploadURL, canPreprocess, onDone, onFail, onProgress) {
    try {
        const resizedImage: Blob = canPreprocess ? await resizeImage(fileObj) : fileObj;
        sendBlob(resizedImage, uploadURL, onDone, onFail, onProgress);
    } catch (e) {
        onFail(e);
    }
}
