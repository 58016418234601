// Autogenerated locales - do not modify
/* tslint:disable quotemark variable-name */
export const BO_ORDER_PRODUCT_AMBASSADOR = "Ambas\u00e1dorka";
export const BO_ORDER_PRODUCT_BILLBOARD = "Billboard";
export const BO_ORDER_PRODUCT_BRANDING = "Branding";
export const BO_ORDER_PRODUCT_CONTEST = "Sout\u011b\u017e";
export const BO_ORDER_PRODUCT_COUNSELING = "Poradna";
export const BO_ORDER_PRODUCT_DAYS_WITH = "Dny se zna\u010dkou";
export const BO_ORDER_PRODUCT_HALFPAGE = "Halfpage";
export const BO_ORDER_PRODUCT_HALFPAGE_VIDEO = "Video ve Halfpage";
export const BO_ORDER_PRODUCT_IP = "Zpr\u00e1va do IP";
export const BO_ORDER_PRODUCT_IP_REMARKETING = "Zpr\u00e1va do IP s remarketingem";
export const BO_ORDER_PRODUCT_LARGE_RECTANGLE = "Large Rectangle";
export const BO_ORDER_PRODUCT_LARGE_RECTANGLE_VIDEO = "Video ve Large Rectangle";
export const BO_ORDER_PRODUCT_NATIVE_ARTICLE = "Nativn\u00ed \u010dl\u00e1nek";
export const BO_ORDER_PRODUCT_NATIVE_CONTENT_AD = "Native Content Ad";
export const BO_ORDER_PRODUCT_OUTSTREAM_VIDEO = "Outstream video";
export const BO_ORDER_PRODUCT_PR_ARTICLE = "PR \u010dl\u00e1nek";
export const BO_ORDER_PRODUCT_PRO_ESHOP = "Roz\u0161\u00ed\u0159en\u00ed PRO pro eshop";
export const BO_ORDER_PRODUCT_REDACTION_ARTICLE = "Redak\u010dn\u00ed \u010dl\u00e1nek";
export const BO_ORDER_PRODUCT_RESEARCH = "Pr\u016fzkum";
export const BO_ORDER_PRODUCT_REVIEWS_DETAILED_REVIEWS = "Centrum recenz\u00ed: U\u017eivatelsk\u00e9 testov\u00e1n\u00ed";
export const BO_ORDER_PRODUCT_REVIEWS_PAGE = "Centrum recenz\u00ed: Produktov\u00e1 str\u00e1nka";
export const BO_ORDER_PRODUCT_REVIEWS_PROMO = "Centrum recenz\u00ed: Propagace benefit\u016f";
export const BO_ORDER_PRODUCT_REVIEWS_PROMO_EXTRA = "Centrum recenz\u00ed: Propagace benefit\u016f - plus kreativa";
export const BO_ORDER_PRODUCT_REVIEWS_REDACTION_TESTING = "Centrum recenz\u00ed: Redak\u010dn\u00ed testov\u00e1n\u00ed*";
export const BO_ORDER_PRODUCT_REVIEWS_REDACTION_TESTING_WITH_EXPS = "Centrum recenz\u00ed: Redak\u010dn\u00ed testov\u00e1n\u00ed";
export const BO_ORDER_PRODUCT_REVIEWS_SAMPLING = "Sampling: undefined";
export const BO_ORDER_PRODUCT_REVIEWS_YOUTUBE = "Centrum recenz\u00ed: YouTube p\u0159edstaven\u00ed";
export const BO_ORDER_PRODUCT_SEO_ARTICLE = "SEO \u010dl\u00e1nek";
export const BO_ORDER_PRODUCT_TESTING = "Zku\u0161enosti se zna\u010dkou";
export const BO_ORDER_PRODUCT_UNKNOWN = "Jin\u00e9";
export const BO_ORDER_PRODUCT_YOUTUBE = "YouTube video";
export const BO_ORDERS_INVOICING = "Fakturace";
export const DASH_ORDERS = "Objedn\u00e1vky";
export const DASH_ORDERS_CHART = "Graf";
export const DASH_ORDERS_SIGNED = ["Podepsan\u00e1", "Podepsan\u00e9", ""];
export const DASH_ORDERS_STATS = "Statistiky";
