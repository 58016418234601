import {
    BO_ORDER_PRODUCT_AMBASSADOR,
    BO_ORDER_PRODUCT_BILLBOARD,
    BO_ORDER_PRODUCT_BRANDING,
    BO_ORDER_PRODUCT_CONTEST,
    BO_ORDER_PRODUCT_COUNSELING,
    BO_ORDER_PRODUCT_DAYS_WITH,
    BO_ORDER_PRODUCT_HALFPAGE,
    BO_ORDER_PRODUCT_HALFPAGE_VIDEO,
    BO_ORDER_PRODUCT_IP,
    BO_ORDER_PRODUCT_IP_REMARKETING,
    BO_ORDER_PRODUCT_LARGE_RECTANGLE,
    BO_ORDER_PRODUCT_LARGE_RECTANGLE_VIDEO,
    BO_ORDER_PRODUCT_NATIVE_ARTICLE,
    BO_ORDER_PRODUCT_NATIVE_CONTENT_AD,
    BO_ORDER_PRODUCT_OUTSTREAM_VIDEO,
    BO_ORDER_PRODUCT_PR_ARTICLE,
    BO_ORDER_PRODUCT_PRO_ESHOP,
    BO_ORDER_PRODUCT_REDACTION_ARTICLE,
    BO_ORDER_PRODUCT_RESEARCH,
    BO_ORDER_PRODUCT_REVIEWS_DETAILED_REVIEWS,
    BO_ORDER_PRODUCT_REVIEWS_PAGE,
    BO_ORDER_PRODUCT_REVIEWS_PROMO,
    BO_ORDER_PRODUCT_REVIEWS_PROMO_EXTRA,
    BO_ORDER_PRODUCT_REVIEWS_REDACTION_TESTING,
    BO_ORDER_PRODUCT_REVIEWS_REDACTION_TESTING_WITH_EXPS,
    BO_ORDER_PRODUCT_REVIEWS_SAMPLING,
    BO_ORDER_PRODUCT_REVIEWS_YOUTUBE,
    BO_ORDER_PRODUCT_SEO_ARTICLE,
    BO_ORDER_PRODUCT_TESTING,
    BO_ORDER_PRODUCT_UNKNOWN,
    BO_ORDER_PRODUCT_YOUTUBE,
    BO_ORDERS_INVOICING,
    DASH_ORDERS,
    DASH_ORDERS_CHART,
    DASH_ORDERS_SIGNED,
    DASH_ORDERS_STATS,
} from 'mk/autogenerated/translations/utils.0399f6ee2e76cc836f3da59b82874105'
import { localDatumToDate } from 'mk/common/timeUtils';
import { ACTIVE_SERVER_ID } from 'mk/settings';
import { BoOrderItem, BoOrderItemProductType, MoneyAmount, OrderEntity, ScreenshotType } from 'mk2/apps/bo/schemas';
import { boOrdersAgencyBonusesUrl, boOrdersChartUrl, boOrdersInvoicingUrl, boOrdersSignedUrl, boOrdersUrl } from 'mk2/apps/bo/urls';
import { Btn, BtnType } from 'mk2/components/Btn';
import { Photo, STATUS } from 'mk2/decorators/withUploader';
import { dateDiff, dateLocal, prettyDateRange } from 'mk2/helpers/timeformat';
import { PhotoEntity } from 'mk2/schemas';
import { interpolate } from 'mk2/services/i18n';
import React from 'react';

// keep synced with SCREENSHOT_TYPES in apps/bo/models.py
export const SCREENSHOT_TYPES_BY_PRODUCT: {[K in BoOrderItemProductType]?: ScreenshotType[]} = {
    [BoOrderItemProductType.AMBASSADOR]: [],
    [BoOrderItemProductType.BILLBOARD]: [ScreenshotType.Desktop],
    [BoOrderItemProductType.BRANDING]: [ScreenshotType.Desktop],
    [BoOrderItemProductType.CONTEST]: [ScreenshotType.Notif, ScreenshotType.Forum, ScreenshotType.Facebook],
    [BoOrderItemProductType.COUNSELING]: [ScreenshotType.Desktop],
    [BoOrderItemProductType.DAYS_WITH]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
    [BoOrderItemProductType.HALFPAGE]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
    [BoOrderItemProductType.HALFPAGE_VIDEO]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
    [BoOrderItemProductType.IP]: [ScreenshotType.Desktop],
    [BoOrderItemProductType.IP_REMARKETING]: [ScreenshotType.Desktop],
    [BoOrderItemProductType.LARGE_RECTANGLE]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
    [BoOrderItemProductType.LARGE_RECTANGLE_VIDEO]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
    [BoOrderItemProductType.NATIVE_ARTICLE]: [ScreenshotType.Desktop, ScreenshotType.Mobile, ScreenshotType.Facebook],
    [BoOrderItemProductType.NATIVE_CONTENT_AD]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
    [BoOrderItemProductType.OUTSTREAM_VIDEO]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
    [BoOrderItemProductType.PR_ARTICLE]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
    [BoOrderItemProductType.PRO_ESHOP]: [],
    [BoOrderItemProductType.REDACTION_ARTICLE]: [ScreenshotType.Desktop, ScreenshotType.Mobile, ScreenshotType.Facebook],
    [BoOrderItemProductType.RESEARCH]: [ScreenshotType.Desktop, ScreenshotType.Mobile],

    [BoOrderItemProductType.REVIEWS_DETAILED_REVIEWS]: [ScreenshotType.Desktop],
    [BoOrderItemProductType.REVIEWS_PAGE]: [ScreenshotType.Desktop, ScreenshotType.Mobile, ScreenshotType.Google],
    [BoOrderItemProductType.REVIEWS_PROMO]: [ScreenshotType.Desktop, ScreenshotType.Facebook],
    [BoOrderItemProductType.REVIEWS_PROMO_EXTRA]: [],
    [BoOrderItemProductType.REVIEWS_REDACTION_TESTING]: [ScreenshotType.Desktop],
    [BoOrderItemProductType.REVIEWS_REDACTION_TESTING_WITH_EXPS]: [ScreenshotType.Desktop],
    [BoOrderItemProductType.REVIEWS_SAMPLING]: [ScreenshotType.Desktop],
    [BoOrderItemProductType.REVIEWS_YOUTUBE]: [ScreenshotType.Desktop],

    [BoOrderItemProductType.SEO_ARTICLE]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
    [BoOrderItemProductType.TESTING]: [ScreenshotType.Desktop, ScreenshotType.Mobile, ScreenshotType.Facebook],
    [BoOrderItemProductType.UNKNOWN]: [],
    [BoOrderItemProductType.YOUTUBE]: [ScreenshotType.Desktop, ScreenshotType.Mobile],
};

export const OPTIONAL_SCREENSHOT_TYPES_BY_PRODUCT: {[K in BoOrderItemProductType]?: ScreenshotType[]} = {
    [BoOrderItemProductType.REVIEWS_PAGE]: [ScreenshotType.Desktop, ScreenshotType.Mobile, ScreenshotType.Google],
    [BoOrderItemProductType.REVIEWS_PROMO]: [ScreenshotType.Facebook],
};

export const isOrderCompletedBySales = (order: OrderEntity): boolean => {
    if (!order.agency) {
        return false;
    }

    if (!order.signedDate) {
        return false;
    }

    if (!order.ownerId) {
        return false;
    }

    return order.items.every((item) => !item.fieldsNotCompletedBySales || item.fieldsNotCompletedBySales.length === 0);
};

export function fieldsNotCompletedByProductionAndStartingSoon(item: BoOrderItem, numDays: number): string[] {
    return isStartingInNumDays(item, numDays)
        ? item.fieldsNotCompletedByProduction
        : [];
}

export function itemsNotCompletedByProductionAndStartingSoon(order: OrderEntity, numDays: number): string[] {
    const ret = [];
    for (const item of order.items) {
        const fields = fieldsNotCompletedByProductionAndStartingSoon(item, numDays);
        if (fields.length > 0) {
            let s = `#${item.id}`;
            if (item.dateFrom) {
                s += ' ';
                s += isDateToRequired(item.productType)
                    ? prettyDateRange(localDatumToDate(item.dateFrom),
                                    localDatumToDate(item.dateTo || item.dateFrom))
                    : dateLocal(localDatumToDate(item.dateFrom), false);
            }
            if (item.name) {
                s += ' ' + item.name;
            }

            s += ' ' + fields.map((f) => `-${f}`).join(' ');
            ret.push(s);
        }
    }
    return ret;
}

export function ordersHeaderTabs(active: string) {
    return [(
        <Btn
            key="orders"
            type={BtnType.Tab}
            label={DASH_ORDERS}
            link={boOrdersUrl}
            active={active === 'orders'}
        />), (
        <Btn
            key="orders-chart"
            type={BtnType.Tab}
            label={DASH_ORDERS_CHART}
            link={boOrdersChartUrl}
            active={active === 'orders-chart'}
        />), (
        <Btn
            key="orders-invoicing"
            type={BtnType.Tab}
            label={BO_ORDERS_INVOICING}
            link={boOrdersInvoicingUrl}
            active={active === 'orders-invoicing'}
        />), (
        <Btn
            key="orders-signed"
            type={BtnType.Tab}
            label={interpolate(DASH_ORDERS_SIGNED, {count: 2})}
            link={boOrdersSignedUrl}
            active={active === 'orders-signed'}
        />), (
        <Btn
            key="orders-bonuses"
            type={BtnType.Tab}
            label="Bonuses"
            link={boOrdersAgencyBonusesUrl}
            active={active === 'orders-bonuses'}
        />), (
        <Btn
            key="orders-stats"
            type={BtnType.Tab}
            label={DASH_ORDERS_STATS}
            link="https://docs.google.com/a/modrykonik.com/spreadsheets/d/1HpwMFeApY93Le9bcyrRECGBNDTxKDrNmWp-7hhxJv6o/edit?usp=sharing"
            linkTarget="_blank"
            asAnchor
        />
    )];
}

export const NIFTY_QUOTES_URL = (nqId) => `http://ponuky.modrykonik.com/proposals/${nqId}/overview`;
export const PIPEDRIVE_URL = (pdId) => `https://modrykonik.pipedrive.com/deal/${pdId}`;

export const monthsBetween = function*(startYear, startMonth, endYear, endMonth) {
    let year = startYear;
    let month = startMonth;

    while (true) {
        if (year > endYear) {
            return;
        }
        if (year === endYear && month > endMonth) {
            return;
        }

        yield [year, month];

        month++;
        if (month > 12) {
            year++;
            month = 1;
        }
    }
};

export const ambassadorMonths = (dateFrom, dateTo) => {
    if (!(dateFrom && dateTo)) {
        return [];
    }
    const [ startYear, startMonth ] = dateFrom.split('-').filter((x, idx) => idx < 2).map((x) => parseInt(x, 10));
    const [ endYear, endMonth ] = dateTo.split('-').filter((x, idx) => idx < 2).map((x) => parseInt(x, 10));
    return monthsBetween(startYear, startMonth, endYear, endMonth);
};

// TBD: brano: cleaner solution
const photoEntityToPhoto = (photoEntity): Photo => {
    const ratio = photoEntity.width / photoEntity.height;
    let thumbWidth;
    let thumbHeight;
    if (photoEntity.width > photoEntity.height) {
        thumbWidth = 150;
        thumbHeight = thumbWidth / ratio;
    } else {
        thumbHeight = 150;
        thumbWidth = thumbHeight * ratio;
    }
    return {
        status: STATUS.DONE,
        errorMsg: null,
        progress: null,
        obj: {},
        orientation: photoEntity.orientation,
        thumb: {
            width: thumbWidth,
            height: thumbHeight,
            url: photoEntity.url150,
        },
        ...photoEntity,
    };
};

export const photoEntitiesToPhotos = (photoEntities: PhotoEntity[]): Photo[] => {
    return photoEntities.map((photoEntity) => photoEntityToPhoto(photoEntity));
};

export const photoEntityToPhotos = (photoEntity: PhotoEntity): Photo[] => {
    return photoEntity
        ? photoEntitiesToPhotos([photoEntity])
        : null;
};

export function moneyPretty(val: MoneyAmount,
                            removeZeros: boolean = true,
                            showCurrency: boolean = true,
                            shortCurrency: boolean = false,
                            separator = null): string {
    let snum = val.value.toFixed(2);

    // remove trailing zeros
    if (removeZeros) {
        snum = snum.replace(/.00$/, '');
    }

    if (!separator) {
        separator = val.currency in ['EUR', 'Kč', 'CZK'] ? ',' : '.';
    }
    snum = snum.replace('.', separator);

    if (showCurrency) {
        // convert from ISO currency code
        let currency;
        if (val.currency === 'CZK') {
            currency = 'Kč';
        } else if (val.currency === 'EUR') {
            currency = shortCurrency ? '€' : 'EUR';
        } else {
            currency = val.currency;
        }

        return `${snum} ${currency}`;
    } else {
        return `${snum}`;
    }
}

export function moneySum(vals: MoneyAmount[]): MoneyAmount {
    const total: MoneyAmount = {
        value: 0,
        currency: {201: 'EUR', 202: 'CZK'}[ACTIVE_SERVER_ID],
    };

    for (const val of vals) {
        if (val.currency !== total.currency) {
            throw new Error('Unexpected currency');
        }

        total.value += val.value;
    }

    return total;
}

export function moneyDiff(val1: MoneyAmount, val2: MoneyAmount): MoneyAmount {
    const total: MoneyAmount = {
        value: 0,
        currency: {201: 'EUR', 202: 'CZK'}[ACTIVE_SERVER_ID],
    };

    if (val1.currency !== total.currency || val2.currency !== total.currency) {
        throw new Error('Unexpected currency');
    }

    total.value += val1.value - val2.value;

    return total;
}

export function percentPretty(val: number, removeZeros: boolean = true) {
    let snum = val.toFixed(2);

    // remove trailing zeros
    if (removeZeros) {
        snum = snum.replace(/.00$/, '');
    }

    return `${snum}%`;
}

export const ORDER_ITEM_PRODUCT_NAMES: {[type in BoOrderItemProductType]: string} = {
    [BoOrderItemProductType.AMBASSADOR]: BO_ORDER_PRODUCT_AMBASSADOR,
    [BoOrderItemProductType.BILLBOARD]: BO_ORDER_PRODUCT_BILLBOARD,
    [BoOrderItemProductType.BRANDING]: BO_ORDER_PRODUCT_BRANDING,
    [BoOrderItemProductType.CONTEST]: BO_ORDER_PRODUCT_CONTEST,
    [BoOrderItemProductType.COUNSELING]: BO_ORDER_PRODUCT_COUNSELING,
    [BoOrderItemProductType.DAYS_WITH]: BO_ORDER_PRODUCT_DAYS_WITH,
    [BoOrderItemProductType.HALFPAGE]: BO_ORDER_PRODUCT_HALFPAGE,
    [BoOrderItemProductType.HALFPAGE_VIDEO]: BO_ORDER_PRODUCT_HALFPAGE_VIDEO,
    [BoOrderItemProductType.IP]: BO_ORDER_PRODUCT_IP,
    [BoOrderItemProductType.IP_REMARKETING]: BO_ORDER_PRODUCT_IP_REMARKETING,
    [BoOrderItemProductType.LARGE_RECTANGLE]: BO_ORDER_PRODUCT_LARGE_RECTANGLE,
    [BoOrderItemProductType.LARGE_RECTANGLE_VIDEO]: BO_ORDER_PRODUCT_LARGE_RECTANGLE_VIDEO,
    [BoOrderItemProductType.NATIVE_ARTICLE]: BO_ORDER_PRODUCT_NATIVE_ARTICLE,
    [BoOrderItemProductType.NATIVE_CONTENT_AD]: BO_ORDER_PRODUCT_NATIVE_CONTENT_AD,
    [BoOrderItemProductType.OUTSTREAM_VIDEO]: BO_ORDER_PRODUCT_OUTSTREAM_VIDEO,
    [BoOrderItemProductType.PR_ARTICLE]: BO_ORDER_PRODUCT_PR_ARTICLE,
    [BoOrderItemProductType.REDACTION_ARTICLE]: BO_ORDER_PRODUCT_REDACTION_ARTICLE,
    [BoOrderItemProductType.SEO_ARTICLE]: BO_ORDER_PRODUCT_SEO_ARTICLE,
    [BoOrderItemProductType.RESEARCH]: BO_ORDER_PRODUCT_RESEARCH,
    [BoOrderItemProductType.REVIEWS_DETAILED_REVIEWS]: BO_ORDER_PRODUCT_REVIEWS_DETAILED_REVIEWS,
    [BoOrderItemProductType.REVIEWS_PAGE]: BO_ORDER_PRODUCT_REVIEWS_PAGE,
    [BoOrderItemProductType.REVIEWS_REDACTION_TESTING]: BO_ORDER_PRODUCT_REVIEWS_REDACTION_TESTING,
    [BoOrderItemProductType.REVIEWS_REDACTION_TESTING_WITH_EXPS]: BO_ORDER_PRODUCT_REVIEWS_REDACTION_TESTING_WITH_EXPS,
    [BoOrderItemProductType.REVIEWS_SAMPLING]: BO_ORDER_PRODUCT_REVIEWS_SAMPLING,
    [BoOrderItemProductType.REVIEWS_PROMO]: BO_ORDER_PRODUCT_REVIEWS_PROMO,
    [BoOrderItemProductType.REVIEWS_PROMO_EXTRA]: BO_ORDER_PRODUCT_REVIEWS_PROMO_EXTRA,
    [BoOrderItemProductType.REVIEWS_YOUTUBE]: BO_ORDER_PRODUCT_REVIEWS_YOUTUBE,
    [BoOrderItemProductType.TESTING]: BO_ORDER_PRODUCT_TESTING,
    [BoOrderItemProductType.UNKNOWN]: BO_ORDER_PRODUCT_UNKNOWN,
    [BoOrderItemProductType.YOUTUBE]: BO_ORDER_PRODUCT_YOUTUBE,
    [BoOrderItemProductType.PRO_ESHOP]: BO_ORDER_PRODUCT_PRO_ESHOP,
};

export const makeYearMonth = (year, month) =>
    year + `${month}`.padStart(2, '0');

/**
 * compares three numbers a, b, c and returns a dict with number that is different, e.g.
 * for A=3, B=5, C=3 returns {B: true}
 * for A=3, B=3, C=5 returns {C: true}
 * for A=3, B=4, C=5  returns {A: true, B: true, C: true}
 */
export function whatIsDifferent(A: number, B: number, C: number): {a?: boolean, b?: boolean, c?: boolean} {
    const diff: {a?: boolean, b?: boolean, c?: boolean} = {};

    if (A === B) {
        if (B === C) {
            // A = B = C
        } else {
            // A = B != C
            diff.c = true;
        }
    } else {
        if (B === C) {
            // A != B = C
            diff.a = true;
        } else {
            if (A === C) {
                // A = C != B
                diff.b = true;
            } else {
                // A != B != C
                diff.a = true;
                diff.b = true;
                diff.c = true;
            }
        }
    }

    return diff;
}

export function isDateFromRequired(productType: BoOrderItemProductType): boolean {
    return (
        productType !== BoOrderItemProductType.UNKNOWN &&
        productType !== BoOrderItemProductType.REVIEWS_PROMO_EXTRA
    );
}

export function isDateToRequired(productType: BoOrderItemProductType): boolean {
    return (
        productType !== BoOrderItemProductType.IP &&
        productType !== BoOrderItemProductType.IP_REMARKETING &&
        productType !== BoOrderItemProductType.NATIVE_ARTICLE &&
        productType !== BoOrderItemProductType.PR_ARTICLE &&
        productType !== BoOrderItemProductType.REDACTION_ARTICLE &&
        productType !== BoOrderItemProductType.SEO_ARTICLE &&
        productType !== BoOrderItemProductType.REVIEWS_PAGE &&
        productType !== BoOrderItemProductType.UNKNOWN &&
        productType !== BoOrderItemProductType.REVIEWS_YOUTUBE &&
        productType !== BoOrderItemProductType.REVIEWS_PROMO_EXTRA
    );
}

export function sortedByDateFromAndName(items: BoOrderItem[]): BoOrderItem[] {

    const safecmp = (v1: string, v2: string): number => {
        if (!v1 && !v2) {
            return 0;
        } else if (!v1 && v2) {
            return 1;
        } else if (v1 && !v2) {
            return -1;
        } else {
            return v1.localeCompare(v2);
        }
    };

    const cmp = (item1: BoOrderItem, item2: BoOrderItem): number =>
        safecmp(item1.dateFrom, item2.dateFrom) ||
        safecmp(item1.name, item2.name);

    return Array.from(items).sort(cmp);
}

export function isStartingInNumDays(item: BoOrderItem, numDays: number): boolean {
    const daysUntilStart = item.dateFrom
        ? dateDiff(new Date(), localDatumToDate(item.dateFrom)).diffDays
        : Infinity;
    return daysUntilStart <= numDays;
}
